import { isString, isUndefined } from "lodash";
import { APP_MACRO_ROLES, DocumentsData, NotificationsData } from "../constants";

export enum JobApplyMethod {
  externalApplyPage = "externalApplyPage",
  URLAutogeneration = "URLAutogeneration",
  quickApplyPage = "quickApplyPage",
  directInRC = "directInRC",
  standaloneApplyPage = "standaloneApplyPage",
  candidatePortalPage = "candidatePortalPage",
}

export enum PhaseStatus {
  waiting = "waiting",
  invited = "invited",
  created = "created",
  sent = "sent",
  opened = "opened",
  started = "started",
  processing = "processing",
  pending = "pending",
  completed = "completed",
  failed = "failed",
  skipped = "skipped",
  cancelled = "cancelled",
}

export enum PhaseFunellingStatus {
  rated = "rated",
  pass = "pass",
  applied = "applied",
  invited_but_not_completed = "invited but not completed",
  started_but_not_completed = "started but not completed",
  not_started = "not started",
  not_rated = "not rated",
  hired = "hired",
}

export enum PhaseType {
  jd = -3,
  start = -1,
  video = 0,
  screeningQuestions = 1,
  videoInterview = 2,
  liveInterview = 3,
  hardSkill = 4,
  softSkill = 5,
  phoneCall = 6,
  candidatePresentation = 7,
  shortlist = 8,
  reference = 9,
  offerManagement = 10,
  end = -2,
}

export const PhaseTypeName: { [x: number]: string } = {
  [PhaseType.start]: "candidates.view.phase.start",
  [PhaseType.video]: "creator.card.video",
  [PhaseType.screeningQuestions]: "creator.card.screening",
  [PhaseType.videoInterview]: "creator.card.videointerview",
  [PhaseType.liveInterview]: "home.live",
  [PhaseType.hardSkill]: "creator.process.card.hard.skills",
  [PhaseType.softSkill]: "creator.process.card.soft.skills",
  [PhaseType.phoneCall]: "creator.card.phoneCall",
  [PhaseType.candidatePresentation]: "candidate.presentation",
  [PhaseType.shortlist]: "creator.card.shortlist",
  [PhaseType.reference]: "creator.card.reference.check",
  [PhaseType.offerManagement]: "creator.card.offerManagement",
  [PhaseType.end]: "candidates.view.phase.end",
};

export enum ProcessStatus {
  open = "open",
  close = "closed",
  expire = "expired",
  draft = "draft",
  planned = "planned",
}

export enum Sources {
  recruitmentConnect = "RC",
  infocore = "Infocore",
  infoeurope = "Infoeurope",
  bullhorn = "BH",
  careerAssistant = "CA",
  goeland = "Goeland",
  default = "RC",
}

export interface Process {
  id: string;
  tenantId: number;
  name: string;
  publicName?: string;
  professionName: string;
  createdAt: number;
  expiryDate: number;
  status: string;
  statsTotal: string;
  statsPassed: string;
  statsHalfCompleted: string;
  statsCompleted: string;
  statsPendingValidation: string;
  statsCandidatePhases: StatsCandidatePhases[];
  phases: ProcessPhase[];
  candidates: Candidate[];
  source: string;
  isPersonal: boolean;
  branchId?: string;
  regionId?: string;
  zoneId?: string;
  type?: string;
  jobProcess?: any;
  jobDescriptionProcess?: any;
  openDate?: any;
  recruiter?: any;
  language?: any;
  externalId?: any;
  campaignId?: any;
  client?: any;
  subBrand?: any;
  createdFromProcessTemplate?: Template;
  showJobDescriptionInfo?: boolean;
}

export interface ProcessListResponse {
  page: number;
  size: number;
  numPages: number;
  totalCount: number;
  processes: Process[];
}

export interface PersonalProcessResponse {
  process: Process;
}

export interface ProcessResponse {
  page: number;
  size: number;
  status: string;
  numPages: number;
  createdAt: number;
  expiryDate: number;
  openDate: number;
  totalCount: number;
  recruiter: any;
  name: string;
  language: string;
  externalId: string;
  campaignId: string;
  profession: Profession;
  statsCandidatesPhases: StatsCandidatePhases[];
  candidates: Candidate[];
  phases: ProcessPhase[];
  source: string;
  notificationManageByExternalSystem: boolean;
  isPersonal: boolean;
  client: any;
  subBrand: string;
  createdFromProcessTemplateName?: string;
  id: string;
}
export interface Person {
  displayName: string;
  email?: string;
  location?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  denomination?: string;
  preferredLanguage?: string;
  optout?: boolean;
}

export interface Candidate {
  id: string;
  displayName: string;
  createdAt: Date;
  score: number;
  phasesStats: ProcessPhasesStats[];
  phasesResults: ProcessPhase[];
  isTermsChecked?: boolean;
  person?: Person;
  recruiterName?: string;
  phaseChange?: any;
  liveInterviews?: any[];
  tag?: any;
  recruiterEmail?: string;
  files?: any;
  candidateAction?: any[];
  status?: number;
  processUser?: any;
  tags?: any;
  candidateSharings?: any[];
  cv?: any;
  chatbotStatus?: number;
  phone?: string;
  isHired?: boolean;
  processId?: string;
  availabilityUser?: any;
  additionalDocuments?: AdditionalDocument[];
  language?: string;
  processLanguage?: string;
  candidateLanguage?: string;
}

interface AdditionalDocument {
  additionalDocType: string;
}

export interface StatsCandidatePhases {
  count: number;
  type: number;
  scoreMax: number;
  score: number;
  name: string;
  index?: number;
  isRunByCAChatbot?: boolean;
}

export interface ReferenceResult {
  name: string;
  lastname: string;
  email: string;
  phone: string;
  answers?:
    | Array<{
        id: string;
        answer: string;
      }>
    | [];
  refused?: boolean;
}

export enum CandidateInputType {
  AutoEnrollment = 0, //aka inbound
  RecruiterEnrollment = 1, //aka outbound
}

// What a barbarity this interface is, we should use proper typing for process phases
export interface ProcessPhase {
  videoMethod: VideoMethods;
  references?: Array<ReferenceResult>;
  type: number;
  title?: string;
  attachmentId?: string;
  attachments?: any | { id: string; name: string; size: number }[];
  customMessage?: any | { subject: string; body: string };
  message?: string;
  mediaType?: string;
  videoUrl?: string;
  contentType?: string;
  name?: string;
  isAcceptanceCriteria?: boolean;
  notifyRecruiterToReview?: boolean;
  isOverallScoreRange?: boolean;
  acceptanceCriteria?: number;
  questions?: Questions[];
  pages?: Pages[] | any[];
  pageBreaks?: any[];
  video?: Video;
  candidateSeen?: boolean;
  score?: number;
  videoInterviewURL?: string;
  redirectUrl?: any;
  videoInterviewConfig?: VideoInterviewConfig;
  liveInterview?: LiveInterview;
  candidateNotes?: string;
  candidateScore?: number;
  selectedTests?: AssessmentSkills[] | AssessmentSkillsRequest[];
  skillsToPass?: number;
  target?: string;
  scoreToPassUpdated?: boolean;
  phoneCall?: PhoneCall;
  candidatePresentation?: CandidatePresentation;
  branchId?: string;
  moveToNextPhase?: any;
  publicName?: any;
  isRunByCAChatbot?: boolean;
  screeningAsChat?: boolean;
  successExitMessage?: string;
  qualifyingExitMessage?: string;
  //SHORTLIST
  isVisible?: boolean;
  description?: string;
  rejectionNotification?: boolean;
  rejectionMessage?: string;
  templateId?: string;
  customRejectionMessage?: any;
  isMandatoryCV?: boolean;
  isVisibleCV?: boolean;
  documents?: DocumentsData[];
  differentialAssessmentByQuestionAndSkill?: boolean;
  differentialAssessmentBySkill?: boolean;
  differentialAssessmentScreening?: boolean;
  differentialAssessmentScreeningRetry?: boolean;
  differentialAssessment?: boolean;
  privacyPolicy?: boolean;
  reminderCandidates?: boolean;
  reminderReferencees?: boolean;
  reminderCandidatesAmount?: string;
  reminderCandidatesFrecuency?: string;
  reminderReferenceesAmount?: string;
  reminderReferenceesFrecuency?: string;
  expirationDate?: any;
  nps?: boolean;
  assignRecruiterId?: string;
  watchCompleteVideo?: boolean;
  temp?: boolean;
  loadFromLibrary?: boolean;
  loadFromTemplate?: boolean;
  loadFromProcess?: string;
  notifyRecruiterOnReference?: boolean;
  authorizedProfiles?: any[];
  enabledUserProfilesSelector?: boolean;
  availableSmsSendingSelector?: boolean;
  candidateAdvanced?: boolean;
  inputType?: CandidateInputType;
  jobDescription?: any;
  phaseCancelled?: PhaseCancelled;
  offerManagementConfig?: OfferManagementConfig;
  onBoardingLink?: string;
  minimumReferees?: number;
  notifications?: NotificationsData[];
  CARedirectionUrl?: string;
  branchInterview?: any;
  selectedProcessLanguages?: any;
  runByCAChatbot?: "on";
  automaticRemainder?: "on";
  selectedProcessLanguagesDefault?: string;
}

export enum RateMeasurement {
  hourly = "hourly",
  daily = "daily",
  monthly = "monthly",
  yearly = "yearly",
}

export interface OfferManagementConfig {
  availableShifts: string;
  payRate: {
    rate: number;
    measurement: RateMeasurement;
  };
  workLocation: string;
  specialConditions: string;
  onBoardingLink?: string;
  sendOfferAutomatically: boolean;
  validityPeriod: number;
  offerManagementTemplate: any;
  currency: string;
}

export interface PhaseCancelled {
  reasonForCancel: string;
  emailMessageToCandidate: string;
  cancelledBy?: APP_MACRO_ROLES;
}

export interface AssessmentSkills {
  name: string;
  testId: string;
  normId: string;
  normTitle: string;
  subtopics: Subtopic[];
  skillId?: string;
  skillName?: string;
  groupId?: string;
  groupName?: string;
  scoreMin?: number;
  scoreMax?: number;
  mainTopicId?: string;
  subtopicsToPass?: number;
  hasFinishedTest?: boolean;
  hasStartedTest?: boolean;
  scoringMethod?: number;
  competences?: any[];
  additionalCost?: string;
  compatibleDevice?: number;
}

export interface Subtopic {
  name: string;
  skillName?: string;
  scoreMin: number;
  scoreMax: number;
  id: string;
}

export interface AssessmentSkillsRequest {
  testId: string;
  normId: string;
  normTitle: string;
  scoreMin?: number;
  scoreMax?: number;
  subtopicsToPass?: number;
  mainTopicId?: string;
  subtopics: SubtopicRequest[];
  groupId?: string;
  groupName?: string;
  skillName?: string;
}

export interface SubtopicRequest {
  scoreMin: number;
  scoreMax: number;
  id: string;
}

export interface LiveInterview {
  expirationDate?: number;
  inviteLiveInterviewOption?: string;
  scheduleOption?: any;
  optionTimes?: number[];
  scheduleDate?: number;
  duration?: number;
  liveVideoInterviewId?: string;
  liveVideoInterviewCandidateUrl?: string;
  calendarScheduleId?: string;
  recruiters?: Recruiter[];
  recruiterIds?: string[];
  assignRecruiter?: any;
  startSchedulingPeriod?: number;
  endSchedulingPeriod?: number;
  calendars?: string[];
  customLiveInterviewEmailMessage?: string;
  assignRecruiterId?: string;
  evaluationQuestions?: any[];
  recruiterCalendarEventId?: string;
  syncExpirationDate?: boolean;
}

export interface PhoneCall {
  inviteOption?: string;
  invitePhoneCallOption?: string;
  expirationDate?: number;
  optionTimes?: any[];
  scheduleDate?: number;
  duration?: number;
  branchId?: string;
  timeZone?: string;
  location?: string;
  phoneType?: string; // candidatePhone | specificPhone
  specificPhone?: string;
  calendarScheduleId?: string;
  calendars?: string[];
  scheduleOption?: any;
  assignRecruiterId?: string;
  recruiters?: Recruiter[];
  recruiterIds?: string[];
  customEmail?: any;
  candidateNotes?: string;
  candidateScore?: string;
  recruiterCalendarEventId?: string;
  startSchedulingPeriod?: number;
  endSchedulingPeriod?: number;
  syncExpirationDate?: boolean;
}

export interface CandidatePresentation {
  description: string;
  isCompulsory: boolean;
  manualApproval: boolean;
  limitRecordingSeconds: number;
  maxRetriesAllowed: number;
  numRetries?: number;
  videoUrl?: string;
  skippOverallScore?: boolean;
}

export interface ProcessPhasesStats {
  status: string;
  score?: number;
  type: number;
  date?: Date;
  endDate?: Date;
}

export interface Questions {
  id: string;
  question: string;
  answers?: Answers[];
  isMultiAnswer?: boolean;
  isPartialMatch?: boolean;
  isExactMatch?: boolean;
  isFreeTypeAnswer?: boolean;
  isDateTypeAnswer?: boolean;
  isBinaryTypeAnswer?: boolean;
  isOptionsListTypeAnswer?: boolean;
  answer?: any;
  isRequired: boolean;
  scoreMin?: number;
  scoreMax?: number;
  candidateScore?: number;
  weight?: number;
  skill?: Skill;
  videoUrl?: string;
  candidateVideoUrl?: string;
  candidateNotes?: string;
  mediaUrls?: any[];
  isPartialScore?: boolean;
  isQualifyingQuestion: boolean;
  isOptional?: boolean;
  variables?: string[];
  attribute?: any;
  attributeId?: string;
  screeningQuestionLibraryId?: string;
  autoSelected?: boolean;
  customRejectionMessage?: any;
  answerTime?: number;
  watchCompleteVideo?: boolean;
  videoHasBeenWatched?: boolean;
  // This variables only are using in video interview questions, in screening question phase are in media urls object
  temp?: boolean;
  loadFromLibrary?: boolean;
  loadFromProcess?: string;
  loadFromTemplate?: boolean;
  contentType?: string;
  answered?: boolean;
  multilanguage?: any;
}

export interface Competence {
  name: string;
  type: number;
  selectedTests: any[];
}

export interface Pages {
  questions: any[];
  title?: string;
  previous: boolean;
}

export interface Answers {
  answer: string;
  screeningQuestionAttributeValue?: string;
  screeningQuestionAttributeId?: string;
  isCorrect?: boolean;
  candidateSelected?: boolean;
  weight?: number;
}

export interface Skill {
  id: string;
  name: string;
  info?: any;
  localeName?: string;
  localeInfo?: any;
  globalId?: string;
  isLocal?: boolean;
}

export interface Client {
  id: string;
  name: string;
  globalId?: string;
  isLocal?: boolean;
  file?: File;
  cobrands?: any[];
  branches?: Branch[];
}

export interface Video {
  title: string;
  description: string;
  url: string | undefined;
  mediaType: string;
  contentType: string;
  watchCompleteVideo?: boolean;
  temp?: boolean;
  loadFromLibrary?: boolean;
  loadFromProcess?: string;
  loadFromTemplate?: boolean;
}

export interface RowItem {
  name: string;
  id: number;
}

export interface Order {
  isProcessActive: boolean;
  isDateActive: boolean;
  isExtirationDateActive: boolean;
  isStatusActive: boolean;
  isAscending: boolean;
  isPendingActive: boolean;
  name: string;
}

export interface DetailsOrder {
  isCandidateActive: boolean;
  isDateActive: boolean;
  isScoreActive: boolean;
  isHiredActive: boolean;
  isAscending: boolean;
  name: string;
}

export interface Category {
  id: string;
  name: string;
  localeName?: string;
  userId?: string;
  removed?: boolean;
}

export interface Template {
  id: string;
  name: string;
  phases?: ProcessPhase[];
  publicName?: string;
}

export interface ProcessCard extends ProcessPhase {
  id: string;
  isSeen: boolean;
  index?: number;
  isWellFormed?: boolean;
}
export interface StartEndCard {
  title: string;
  type: number;
  message: string;
  videoUrl?: string;
  videoExtension?: string;
  templateId?: string;
  customMessage?: string;
  attachmentId?: string;
  attachments?: any[];
  isSeen?: boolean;
  mediaType: string;
  loadFromLibrary?: boolean;
  isMandatoryCV?: boolean;
  isVisibleCV?: boolean;
  documents?: DocumentsData[];
  isVisibleAdditionalDocs?: boolean;
  customRejectionMessage?: any;
  subBrand?: string;
  enabledSubBrands?: boolean;
  watchCompleteVideo?: boolean;
  temp?: boolean;
  loadFromProcess?: string;
  loadFromTemplate?: boolean;
  enabledUserProfilesSelector?: boolean;
  availableSmsSendingSelector?: boolean;
  smsBody?: string;
  smsTemplateId?: string;
  automaticEnableSelector?: boolean;
  automaticReminderConf?: string;
  availableSmsSendingMail?: boolean;
  contentType?: string;
  expirationDate?: number;
  publicName?: string;
}

export interface SkillListResponse {
  id: string;
  name: string;
  localeName: string;
  language: string;
}

export interface PublicCandidate {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  location: string;
  denomination?: string;
  preferredLanguage?: string;
  isTermsChecked?: boolean;
  phonePrefix?: string;
  termId?: string;
  termLanguage?: string;
  termVersion?: string;
  file?: any;
  fileName?: any;
  fileType?: any;
  cv?: any;
  externalUserId?: string;
  utmSource?: string | null;
  utmMedium?: string | null;
  utmCampaign?: string | null;
  utmTerm?: string | null;
  utmContent?: string | null;
}

export interface SkillQuestion {
  skillId: string;
  id: string;
  question: string;
  localeQuestion: string;
  language: string;
  skill: Skill;
  multilanguage?: any;
}

export interface SkillOrder {
  isSkillActive: boolean;
  isQuestionActive: boolean;
  isAscending: boolean;
  name: string;
}

export interface SkillQuestionResponse {
  page: number;
  size: number;
  numPages: number;
  totalCount: number;
  videoQuestions: SkillQuestion[];
}

export interface SkillResponse {
  id: string;
  name: string;
  info: {
    star: number;
    definition: string;
  }[];
  locale?: any;
}

export interface VideoInterviewConfig {
  answerTime: number;
  maxRetries: number;
  expireDate?: number;
  expirationDate?: any;
  brandId?: string;
  cammioBrandingId?: string;
}

export interface LiveVideoInterviewConfig {
  inviteOption: number;
  duration?: number;
  expiration?: number;
  schedule?: number;
  interviewTimes?: number[];
}

export interface UserProperties {
  disableSpam: boolean;
  developerFlag?: boolean;
  onConfigurationChangeEmail: boolean;
  maxResults?: number;
}
export interface Availability {
  tenantId: number;
  userId: string;
  timeZone: string;
  availabilityDays: object;
  createdAt: Date;
  updatedAt: Date;
}
export interface TenantResponse {
  id: string;
  name: string;
  email: string;
  properties: UserProperties;
  tenants: TenantObject[];
  phone?: string;
  userTenant: any;
  releaseVersion?: string;
  frontendVersion?: string;
}

export interface UserModules {
  candidateSourcing: boolean;
  recruitmentConnect: boolean;
}
export interface TenantObject {
  id: number;
  alias: string;
  name: string;
  languages: string[];
  roles: string[];
  permissions: {
    adminPanel: {
      templates: boolean;
      users: boolean;
      languages: boolean;
      terms: boolean;
      tenants: boolean;
      categories: boolean;
      skills: boolean;
      clientStats: boolean;
      recruiterStats: boolean;
      candidateFeedback: boolean;
      videoQuestions: boolean;
      xpertCatalog: boolean;
      videoLibrary: boolean;
      screeningQuestions: boolean;
      tenantTags: boolean;
      calendars: boolean;
      clients: boolean;
      variables: boolean;
      operational: boolean;
      screeningAttributes: boolean;
      externalSystems: boolean;
      externalSystemsOperationTenant: boolean;
      Operation: boolean;
      links: boolean;
      referenceCheck: boolean;
      jobdescriptionsections: boolean;
      jobDescriptionBoards: boolean;
    };
    userModules: UserModules;
  } | null;
  branches: Branch[];
  configuration: any[];
  brand: Brand;
  additionalData?: any;
  isoCountryCode?: string;
  availability: Availability;
}

export interface Brand {
  id: string;
  name: string;
  brandImage: string;
  officialName?: string;
  displayName?: string;
  code?: string;
}

export interface Region {
  regionId?: string;
  regionName: string;
  regionCode: string;
  branches?: Branch[];
}

export interface Zone {
  zoneId?: string;
  zoneName: string;
  zoneAddress: string;
  zoneRemoteId?: string;
  regionId?: string;
  regionName?: string;
  regionCode?: string;
  branches?: Branch[];
}

export interface Branch {
  branchId?: string;
  branchName: string;
  branchAddress: string;
  branchPhone?: string;
  branchEmail?: string;
  branchRemoteId?: string;
  regionId?: string;
  regionName?: string;
  regionCode?: string;
  zoneId?: string;
  zoneName?: string;
  zoneAddress?: string;
  zoneRemoteId?: string;
  divisionId?: string;
  businessLineId?: string;
  costCenter?: string;
  additionalData?: { germanLabourOfficeClientId?: string };
}

export interface SelectedTenantObject {
  id: number;
  alias: string;
  tenant: number;
  theme: string;
  languages: string[];
  selectedTenantConfiguration: any[];
  branches: Branch[];
  regionId?: string;
  branchId?: string;
  zoneId: string;
  brand: Brand;
  additionalData: any;
  isoCountryCode?: string;
}

export interface TenantUser {
  id?: string;
  name: string;
  email: string;
  roles: string[];
  phone?: string;
  branches?: Branch[];
  permissions?: {
    adminPanel: {
      templates: boolean;
      users: boolean;
      languages: boolean;
      terms: boolean;
      tenants: boolean;
      categories: boolean;
      skills: boolean;
      clientStats: boolean;
      recruiterStats: boolean;
      candidateFeedback: boolean;
      videoQuestions: boolean;
      xpertCatalog: boolean;
      videoLibrary: boolean;
      screeningQuestions: boolean;
      tenantTags: boolean;
      calendars: boolean;
      clients: boolean;
      variables: boolean;
      operational: boolean;
      screeningAttributes: boolean;
      referenceCheck: boolean;
    };
    userModules: {
      candidateSourcing: boolean;
      recruitmentConnect: boolean;
    };
  };
  authorizedBoards: any[];
  availability?: Availability;
}

export interface TenantSendUser {
  id?: string;
  name: string;
  email: string;
  role: string[];
  branches?: Branch[];
  userAllowedModules?: string | string[];
  permissions?: {
    adminPanel: {
      templates: boolean;
      users: boolean;
      languages: boolean;
      terms: boolean;
      tenants: boolean;
      categories: boolean;
      skills: boolean;
      clientStats: boolean;
      recruiterStats: boolean;
      candidateFeedback: boolean;
      videoQuestions: boolean;
      xpertCatalog: boolean;
      videoLibrary: boolean;
      screeningQuestions: boolean;
      tenantTags: boolean;
      calendars: boolean;
      clients: boolean;
      variables: boolean;
      operational: boolean;
      screeningAttributes: boolean;
      referenceCheck: boolean;
    };
    userModules: {
      candidateSourcing: boolean;
      recruitmentConnect: boolean;
    };
  };
}

export interface TenantsAdmin {
  id: number;
  alias: string;
  brand?: any;
  brandId?: number;
  domains?: [];
  name: string;
  isoCountryCode: string;
  additionalData: { findJobsUrl?: string };
}

export interface VideoInterviewQuestionAdmin {
  skillId: string;
  id: string;
  localeQuestion: string;
  multilanguage?: Record<string, any>;
}

export interface Profession {
  id?: string;
  name: string;
  localeName: string;
  industryId?: string;
  isLocal?: boolean;
  globalId?: string;
  kernelId?: string;
  locale?: SendUpsertIndustry;
}

export interface SendUpdateTemplate {
  id: string;
  name: string;
  userId: string | null;
}

export interface SendUpsertIndustry {
  id?: string;
  name: string;
  localeName: string;
}

export interface SendUpsertTag {
  id?: string;
  name: string;
  isStandard: boolean;
  categoryTagId: string;
}

export interface SendUpsertLocalTag {
  id?: string;
  tagId: string;
  name: string;
}

export interface SendUpsertScreeningQuestionType {
  id?: string;
  name: string;
}

export interface SendUpsertScreeningQuestionTypeLanguage {
  id?: string;
  name: string;
  localeName: string;
}

export interface CammioSettings {
  answerTime: number;
  brandingId?: number;
  maxRetries: number;
  disclaimer?: boolean;
}

export interface MailTemplate {
  emailType: string;
  emailTypeId: string;
  id: string;
  type?: string;
  language: string;
  mailjetId: string;
  tenantId: number | null;
  updatedAt: string;
  createdAt: string;
}

export interface FeatureConfigurationType {
  channel: string;
  feature: string;
  config?: any;
  isEnabled: boolean;
  tenantId?: number;
}
export interface LiveInterviewExtra {
  id: string;
  status?: PhaseStatus;
  scheduleDate?: number;
  duration?: number;
  inviteLiveInterviewOption: string;
  expirationDate?: number;
  scheduleOption?: string;
  optionTimes?: any[];
  reviewers?: string[];
  liveVideoInterviewId?: string;
  liveVideoInterviewCandidateUrl?: string;
  liveVideoInterviewInterviewerUrl?: string;
  liveVideoInterviewReviewer1Url?: string;
  liveVideoInterviewReviewer2Url?: string;
  candidateNotes?: string;
  score?: string;
}

export interface XpertCatalogUpdate {
  status: string;
  message: string;
  createdAt: number;
}

export interface GlobalCompetencyFrameworkSkill {
  globalCompetencyFrameworkId: string;
  globalCompetencyFrameworkName: string;
  skill: Skill;
}

export interface ScreeningQuestionLibrary {
  id?: string;
  tenantId?: string;
  language?: string;
  questionId?: string;
  questionType: string;
  question: string;
  mediaUrlQuestions?: any[];
  answers: any;
  isMultiAnswer?: boolean;
  isPartialMatch?: boolean;
  isExactMatch?: boolean;
  isFreeTypeAnswer?: boolean;
  isDateTypeAnswer?: boolean;
  isOptionsListTypeAnswer?: boolean;
  isBinaryTypeAnswer?: boolean;
  isRequired: boolean;
  isQualifyingQuestion: boolean;
  weight?: number;
  isPartialScore?: boolean;
  type?: any;
  isOptional?: boolean;
  variables?: string[];
  attributeId?: string;
  [others: string]: any;
}

export interface ReferenceCheckLibrary {
  tenantId?: string;
  language?: string;
  question: string;
  id?: string;
  answers: any;
  isMultiAnswer?: boolean;
  isFreeTypeAnswer?: boolean;
}

export interface Position {
  tenantId?: string;
  name: string;
  localeName?: string;
  id?: string;
}

export interface ContractType {
  tenantId?: string;
  name: string;
  localeName?: string;
  id?: string;
}

export interface OfferTemplate {
  tenantId?: string;
  name: string;
  id?: string;
  multilanguage?: any;
}

export interface UserFeedback {
  score?: number;
  comment?: string;
  commentOption?: string;
  dismiss?: boolean;
}

export interface JobDescriptionBoard {
  id?: string;
  description?: string;
  name: string;
  cost: number;
  hide: boolean;
  notCompatible?: string[];
  defaultPeriods?: number;
  utmFields?: any[];
  customFields?: any[];
  branchId?: string;
  postByDefault?: boolean;
  publisherId?: any;
}
export interface VideoLibraryI {
  videoUrl?: string;
  id?: string;
  title: string;
  description: string;
  type?: any;
  typeId: string;
  videoId: string;
  thumbnail?: string;
  isPersonal: boolean;
  temp?: boolean;
  contentType: string;
  video: { contentType: string };
  sasToken?: string;
}

export interface Calendar {
  id?: string;
  name: string;
  location: string;
  timezone: string;
  startSchedulingPeriod: number;
  endSchedulingPeriod: number;
  removed?: boolean;
  availabilitySlots: CalendarAvailabilitySlot[];
  relations?: CalendarRelation[];
  nonAvailabilityCalendars: string[];
  assignedOutlookCalendarId: string;
  reminderEnable?: boolean;
  reminderEmails?: string[];
}

export interface CalendarAvailabilitySlot {
  id?: string;
  calendarId?: string;
  startHour: number;
  startHourFormat?: string;
  endHour: number;
  endHourFormat?: string;
  duration: number;
  capacity: number;
  weekDays: string;
  slots?: CalendarAvailableSlot[];
}

export interface CalendarAvailableSlot {
  id?: string;
  calendarId?: string;
  calendarSlotId?: string;
  startHour: number;
  endHour: number;
  capacity: number;
}

export interface CalendarRelation {
  id?: string;
  calendarId?: string;
  regionId?: string;
  zoneId?: string;
  branchId?: string;
}

export interface CalendarAppointment {
  id: string;
  date: number;
  duration: number;
  assignedTo?: string;
  type: number;
  candidateId: string;
  personId?: string;
  calendarId: string;
  phaseIndex?: number;
  candidate?: {
    firstName: string;
    lastName: string;
  };
  person?: {
    firstName: string;
    lastName: string;
  };
  typeAppointment?: string;
}

export interface NonAvailabilityCalendar {
  name: string;
  id?: string;
  timezone?: string;
  nonAvailabilityDays: NonAvailabilityDay[];
  calendarsAssigned?: [
    {
      id: string;
      name: string;
    }
  ];
}

export interface NonAvailabilityDay {
  startDate: number;
  startDateFormat?: string;
  endDate: number;
  endDateFormat?: string;
  name: string;
  description: string;
  allDay: boolean;
  calendarId?: string;
  id?: string;
}

export interface ConfirmationDeleteProps {
  onDeleteProcess?: Function;
  setShowDelete?: Function;
  id?: string;
  candidates: number;
  setDeleteId?: Function;
  status: string;
  setStatusDelete?: Function;
}

export interface PendingProps {
  pending: number;
}
export interface TotalProps {
  total: string;
  item: Process;
  index: number;
  showAddModal: Function;
  hideAddIcon: boolean;
}

export interface ReferencesExcel {
  Name: string;
  Email: string;
  Phone: string;
  CandidateId?: string;
  CandidateName?: string;
  CandidateEmail?: string;
  UpdatedAt?: string;
}

export interface ReferencePerson {
  id: string;
  contactBusiness: boolean;
  contactJob: boolean;
  createdAt: Date;
  updatedAt: Date;
  email: string;
  lastname: string;
  name: string;
  phone: string;
  privateStatement: boolean;
  tenantId: number;
}
export interface ReferencesResponseExcel {
  id: string;
  candidate: {
    id: string;
    personId: string;
    person: {
      firstName: string;
      lastName: string;
      email: string;
    };
  };
  referencePerson: ReferencePerson;
}

export interface Recruiter {
  azureAdB2CUserId: string;
  email: string;
  id: string;
  name: string;
  passwordHash: string;
  superAdmin: boolean;
  tenants: TenantObject[];
  updatedAt: Date;
  userTenants: TenantUser[];
  remoteId?: string;
  removed?: boolean;
  removedAt?: Date;
  removedBy?: string;
}

export interface DateTime {
  value: string;
  recruiterId?: string;
}

export enum MediaTypes {
  storedVideo = "video",
  externalVideo = "url",
  image = "image",
  videoUrl = "videoUrl",
}

export type Division = {
  createdAt: string;
  updatedAt: string;
  name: string;
  id: string;
  tenantId: number;
};

export type BusinessLine = {
  createdAt: string;
  updatedAt: string;
  name: string;
  id: string;
  tenantId: number;
};

export type DivisionOrBusinesLine = Division | BusinessLine;

export interface NewClientBrandData {
  brandData: {
    primaryColor: string;
    secondaryColor: string;
  };
  name: string;
  emailColor?: string;
  emailImage?: string;
  emailHeaderImage?: any;
  cammioBrandingId?: {
    DEV?: any;
    LOCAL?: any;
    PROD?: any;
    TEST?: any;
    UAT?: any;
  };
  officialId?: number;
  officialName?: string;
  externalCode?: string;
  code?: string;
}

export interface ClientBrand extends NewClientBrandData {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  brandData: {
    primaryColor: string;
    secondaryColor: string;
    primaryLogo: string;
    secondaryLogo: string;
  };
}

export const isNewClientBrandData = (data: any): data is NewClientBrandData | ClientBrand => {
  if (typeof data !== "object") return false;
  if (!isString(data?.name) || typeof data?.brandData !== "object" || !isString(data.brandData?.primaryColor) || !isString(data.brandData?.secondaryColor)) return false;
  return true;
};

export const isClientBrand = (data: any): data is ClientBrand => typeof data === "object" && data?.id && isString(data.id) && data?.id && isNewClientBrandData(data);

export interface CleanJobDescriptionBudgetQuarterly {
  branch: string;
  id: string;
  branchId: string;
  year: number;
  period_1: number;
  period_1_expend: number;
  period_2: number;
  period_2_expend: number;
  period_3: number;
  period_3_expend: number;
  period_4: number;
  period_4_expend: number;
}

export interface CleanJobDescriptionBudgetMonthly extends CleanJobDescriptionBudgetQuarterly {
  period_5: number;
  period_5_expend: number;
  period_6: number;
  period_6_expend: number;
  period_7: number;
  period_7_expend: number;
  period_8: number;
  period_8_expend: number;
  period_9: number;
  period_9_expend: number;
  period_10: number;
  period_10_expend: number;
  period_11: number;
  period_11_expend: number;
  period_12: number;
  period_12_expend: number;
}

export enum CandidateStatus {
  opened = 1,
  started = 2,
  cancelled = 3,
  cancelledByINFO = 4,
}

export interface JobDescriptionSectionCategory {
  id: string;
  categoryName: string;
  categoryRecommendation: string;
}

export type CleanJobDescriptionBudget = CleanJobDescriptionBudgetMonthly | CleanJobDescriptionBudgetQuarterly;

export const budgetIsMonthlyBudget = (budget: CleanJobDescriptionBudgetQuarterly | CleanJobDescriptionBudgetMonthly): budget is CleanJobDescriptionBudgetMonthly =>
  Object.keys(budget).includes("period_5");

export interface FileAttachment {
  base64: string;
  filename: string;
}

export const notUndefinedFileAttachment = (arg: FileAttachment | undefined): arg is FileAttachment => !isUndefined(arg);

export const ElementIsNotUndefined = (arg: JSX.Element | undefined): arg is JSX.Element => !isUndefined(arg);

export enum VideoMethods {
  record = "record",
  upload = "upload",
  link = "link",
  library = "library",
}

export interface SendEmailWelcomeMessage {
  tenantId: number;
  tenantAlias: string;
  brandColor?: string;
  brandImage?: string;
  processId: string;
  processLanguage?: string;
  processExpiryDate: any;
  processName: string;
  professionName?: string;
  recruiter: PersonContact;
  shouldSendCustomMessage?: boolean;
  customMessage?: string;
  timeZone: string;
  candidates: PersonContactWithId[];
  emailExtraData?: any;
  replyToEmail?: string;
  availableSmsSendingSelector: boolean; // true
  availableSmsSendingMail: boolean; // false
  appDisplayName?: string;
  smsTemplateId: string;
}

export enum VonqStatusUpdateOperationEnum {
  CREATEUPDATE = 1,
  DELETE = 2,
  UPDATE = 3,
}

interface PersonContactWithId extends PersonContact {
  personId?: string;
  id: string;
}

interface PersonContact {
  email: string;
  name: string;
  phone?: string; // mandatory for candidate
  processUrl?: string;
  phonePrefix?: string; // mandatory for candidate
}

export interface IReleaseNoteContent {
  title: string;
  content: string;
  language: string;
}

export interface AdditionalDocuments {
  id: string;
  name: string;
  supportedTypes: string[];
  ambeeType?: string;
}

export enum BinaryAnswerOption {
  Yes = "Yes",
  No = "No",
}

export interface JobdescriptionFetchResult {
  data: {
    totalItems: number;
    items: JobdescriptionItem[];
  };
  status: number;
}
export interface JobdescriptionResult {
  count: number;
  data: JobdescriptionItem[];
}

export interface JobdescriptionItem {
  id: string;
  status: string;
  source: string;
  branchId: null | string;
  versionId: string;
  externalId: null;
  categoryId: null | string;
  vacancyReferenceNumber: null | string;
  jobTitle: null | string;
  externalJobTitle: null | string;
  clientName: null;
  hiringManager: null | string;
  applyUrl?: string;
  urls: any[];
  cobrand: null;
  subbrand: null;
  displayLanguage: null | string;
  process: null | string;
  createdBy: Responsible;
  lastModifiedBy: Responsible;
  createdAt: number;
  updatedAt: number;
  jobDescriptionSchedules: JobDescriptionSchedule[];
}

export interface Responsible {
  id: string;
  name: string;
}

export interface JobDescriptionSchedule {
  id: string;
  statusId: number;
  startDate: Date | null;
  endDate: Date | null;
  boardId: string;
  name: string;
  lastUpdateInfo: unknown;
}

export interface VisibleColumns {
  title: string;
  columnKey: string;
  sortable: boolean;
  jobFilterOptions?: Array<JobFilterOptionClass | string>;
  type?: string;
  hasCheckbox?: boolean;
  width?: number;
  disableReorder?: boolean;
  filterOptions: Array<JobFilterOptionClass | string>;
}

export interface JobFilterOptionClass {
  label: string;
  value: string;
}

export interface ConversationContribution {
  timestamp: Date;
  participant: "recruiter" | "candidate";
  spokenText: string;
  captionText: string;
  captionTextDifferent: boolean;
}

export interface ConversationState {
  contributions: ConversationContribution[];
}

export interface ACSCallState {
  contactPhoneNumber: string | null;
  contactName?: string;
  selectedLanguage?: string;
  startDateTime?: Date;
  endDateTime?: Date;
  acsToken?: string;
  altCallerId?: string;
}
